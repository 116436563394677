import Vue from 'vue'
import App from './App.vue'
import { Swipe, SwipeItem } from 'vant'
import VueGtag from 'vue-gtag'
import { APP_CODE } from '@/pages/momo/config'

require('../../../init')
Vue.config.productionTip = false
Vue.use(Swipe)
Vue.use(SwipeItem)

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_KEY_HY,
    params: {
      language: 'zh-cn',
      content_group: APP_CODE,
    },
  },
})

new Vue({
  render: function (h) {
    return h('div', { attrs: { id: 'app-content' } }, [h(App)])
  },
}).$mount('#app')
